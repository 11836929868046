

.msgBox{
  position: fixed;
  width: 80%;
  padding: 20px 25px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(5px);
  top: 100px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  line-height: 50px;
  color: #fff;
  font-size: 25px;
  z-index: 9999;
  overflow: hidden;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  @media screen and (max-width:768px) {
    font-size: 12px;
    line-height: 23px;
    height: auto;

  }
  @media screen and (max-width:1280px) {
    font-size: 15px;
    line-height: 23px;
    height: auto;
  }
}
.msgContent{
  position: relative;
}
.msgBar{
  margin-top: 20px;
  text-align: end;
  position: absolute;
  bottom: 20px;
  right: 10px;
}
.msgBtn{

  padding: 10px;
  background-color: #0000003c;
  color: #fff;
  border:1px solid #fff;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
}
.msgBox.visible {
  animation-name: slideDown;
  @media screen and (max-width:768px) {
    animation-name: slideDownS;

  }
  @media screen and (max-width:1280px) {
    animation-name: slideDownS;
  }
}
.msgBox.hidden {
  animation-name: slideUp;
  @media screen and (max-width:768px) {
    animation-name: slideUpS;

  }
  @media screen and (max-width:1280px) {
    animation-name: slideUpS;
  }
}
.msgIcon{
  position: relative;
  z-index: 9999999;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #FFF;
  z-index: 999;
  color: #FFF;
  cursor: pointer;
  width: 300px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  left: 0;
  right: 0;
  top: 70px;
  margin: auto;
  font-size: 16px;
  @media screen and (max-width:750px) {
    top: 30px;
  }
}
.msgIcon:hover{
  background-color: rgba(255, 255, 255, 0.416);
} 


.msgTitle{
  margin-bottom: 20px;
}

@keyframes slideDownS {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 400px; /* 或者你想要的展开高度 */
    opacity: 1;
  }
}

@keyframes slideUpS {
  from {
    height: 400px; /* 或者你想要的展开高度 */
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
}
@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 400px; /* 或者你想要的展开高度 */
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    height: 400px; /* 或者你想要的展开高度 */
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
}