* {
  margin: 0;
  padding: 0;
  /* height: 100%; */
  box-sizing: border-box;
  list-style-type: none;
  text-decoration-line: none;

}

@font-face {
  font-family: 'fontspring-extralight';
  src: local('MyFont'), url(./assets/Fontspring-DEMO-altivo-extralight.otf) format('truetype');
}

@font-face {
  font-family: 'fontspring-medium';
  src: local('MyFont'), url(./assets/Fontspring-DEMO-altivo-medium.otf) format('truetype');
}

@font-face {
  font-family: 'fontspring-regular';
  src: local('MyFont'), url(./assets/Montserrat-Regular.ttf) format('truetype');
}

.myDomain {
  /* background-color: #000; */
  /* border-bottom: 1px solid #fff; */
  border-radius: 0;
}

.myDomain:hover {
  background-color: #000;
  /* animation: borderDisappear 2s forwards; */
  border-color: #a5a5a5;
  border-radius: 0;

}
.domainBox{
  position: relative;
  /* border-left: 1px solid #fff;
  border-right: 1px solid #fff; */
  border-radius: 0;
  padding: 10px 15px;
}
.domainBox::before{
  content: "";
  position: absolute;
  width:1px;
  background-color: #585858;
  left: 0;
  height: 90%;
  top: 0;
  margin: auto;
  bottom: 0;
  right: 0;
}
.partnerDomian{
  color: #fff;
  font-size: 18px;
  position: relative;
  margin-bottom: 15px;
}
.partnerDomian::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%);
  width: 35%; /* 根据需要调整线条宽度 */
  border-bottom: 1px solid #1d1d1d; /* 设置虚线样式 */
}
.partnerDomian::after {
  content: '';
  z-index: -2;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%; /* 根据需要调整线条宽度 */
  border-bottom: 1px solid #ccc; /* 设置虚线样式 */
}
.domainPartner{
  padding-top: 20px;
}

/* @keyframes borderDisappear {

  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

  }

  100% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);


  }
} */